import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      <p>
        404 Page Not Found. 
        Try again later !
      </p>
    </div>
  )
}

export default ErrorPage;